var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{key:'lahan-detail' + _vm.componentKey,staticClass:"lahan-detail"},[_c('v-col',{attrs:{"md":"4","xl":"3"}},[_c('v-card',{staticClass:"scooping-visit-detail-card mb-5",attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("mdi-arrow-left-circle")]),_c('h5',{staticClass:"mb-0 pb-0"},[_vm._v("Detail Lahan")])],1),_c('div',{staticClass:"lahan-side-wrapper"},[_c('div',{staticClass:"lahan-side-list"},[_c('h4',[_vm._v("Action")]),(_vm.data.main_lahan)?_c('div',{staticClass:"lahan-side-item-wrapper",staticStyle:{"flex-wrap":"wrap","width":"100%"}},[(_vm.getProject() === 'carbon')?_c('div',{staticClass:"lahan-side-item d-flex flex-col",staticStyle:{"flex-wrap":"wrap"}},[_c('div',[(
                  _vm.$_sys.isAllowed('lahan-fc-unverification-create') &&
                  [0, 1, null].includes(_vm.data.main_lahan.fc_complete_data) &&
                  _vm.data.main_lahan.updated_gis === 'belum'
                )?_c('v-btn',{staticClass:"mr-1 mb-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.unverificationData('fc_complete_data')}}},[_c('v-icon',[_vm._v("mdi-undo-variant")]),_c('span',[_vm._v("Unverifikasi Kelengkapan Data")])],1):_vm._e(),(_vm.$_sys.isAllowed('lahan-um-unverification-create'))?_c('v-btn',{staticClass:"mr-1 mb-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.unverificationData('um_seed')}}},[_c('v-icon',[_vm._v("mdi-undo-variant")]),_c('span',[_vm._v("Unverifikasi Perubahan Bibit")])],1):_vm._e(),(_vm.$_sys.isAllowed('lahan-gis-unverification-create'))?_c('v-btn',{staticClass:"mr-1 mb-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.unverificationData('gis')}}},[_c('v-icon',[_vm._v("mdi-undo-variant")]),_c('span',[_vm._v("Unverifikasi GIS")])],1):_vm._e(),(_vm.$_sys.isAllowed('lahan-um-unverification-create'))?_c('v-btn',{staticClass:"mr-1 mb-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.unverificationData('um_unverification')}}},[_c('v-icon',[_vm._v("mdi-undo-variant")]),_c('span',[_vm._v("Unverifikasi "+_vm._s(_vm.data.main_lahan.approve == 2 ? "UM" : "FC"))])],1):_vm._e()],1),(
                !_vm.openGisEdit &&
                _vm.$_sys.isAllowed('lahan-gis-verification-create') &&
                _vm.data.main_lahan.fc_complete_data == 1
              )?_c('v-btn',{staticClass:"mr-1 mb-1",attrs:{"variant":"success"},on:{"click":function($event){_vm.openGisEdit = true;
              _vm.verifRole = 'gis';}}},[_vm._v("Verifikasi GIS")]):_vm._e(),(
                !_vm.openGisEdit &&
                _vm.$_sys.isAllowed('lahan-fc-verification-create') &&
                _vm.data.main_lahan &&
                ((_vm.data.main_lahan.fc_complete_data == null &&
                  _vm.data.main_lahan.updated_gis.toLowerCase() === 'belum') ||
                  _vm.data.main_lahan.updated_gis.toLowerCase() === 'sudah')
              )?_c('v-btn',{staticClass:"mr-1 mb-2",attrs:{"variant":"success"},on:{"click":function($event){_vm.openGisEdit = true;
              _vm.verifRole =
                _vm.data.main_lahan.fc_complete_data == null &&
                  _vm.data.main_lahan.updated_gis.toLowerCase() == 'belum'
                  ? 'fc-verif-data'
                  : 'fc';}}},[_vm._v("Verifikasi FC")]):_vm._e(),(
                !_vm.openGisEdit &&
                _vm.$_sys.isAllowed('lahan-um-verification-create') &&
                _vm.data.main_lahan &&
                _vm.data.main_lahan.updated_gis.toLowerCase() === 'sudah' &&
                _vm.data.main_lahan.approve == 1
              )?_c('v-btn',{staticClass:"mr-1 mb-2",attrs:{"variant":"success"},on:{"click":function($event){_vm.openGisEdit = true;
              _vm.verifRole = 'um';}}},[_vm._v("Verifikasi UM")]):_vm._e()],1):_vm._e(),(_vm.getProject() === 'non-carbon')?_c('div',{staticClass:"lahan-side-item d-flex flex-col",staticStyle:{"flex-wrap":"wrap"}},[(
                _vm.$_sys.isAllowed('lahan-fc-unverification-create') &&
                [0, 1, null].includes(_vm.data.main_lahan.fc_complete_data)
              )?_c('v-btn',{staticClass:"mr-1 mb-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.unverificationData('fc_complete_data')}}},[_c('v-icon',[_vm._v("mdi-undo-variant")]),_c('span',[_vm._v("Unverifikasi Kelengkapan Data")])],1):_vm._e(),(_vm.$_sys.isAllowed('lahan-um-unverification-create'))?_c('v-btn',{staticClass:"mr-1 mb-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.unverificationData('um_unverification')}}},[_c('v-icon',[_vm._v("mdi-undo-variant")]),_c('span',[_vm._v("Unverifikasi UM")])],1):_vm._e(),(
                !_vm.openGisEdit &&
                _vm.$_sys.isAllowed('lahan-fc-verification-create') &&
                _vm.data.main_lahan &&
                _vm.data.main_lahan.approve == 0
              )?_c('v-btn',{staticClass:"mr-1 mb-2",attrs:{"variant":"success"},on:{"click":function($event){_vm.openGisEdit = true;
              _vm.verifRole = 'fc-non-carbon';}}},[_vm._v("Verifikasi FC")]):_vm._e(),(
                _vm.$_sys.isAllowed('lahan-um-verification-create') &&
                _vm.data.main_lahan &&
                _vm.data.main_lahan.approve == 1
              )?_c('v-btn',{staticClass:"mr-1 mb-2",attrs:{"variant":"success"},on:{"click":function($event){return _vm.verifUmNonCarbon()}}},[_vm._v("Verifikasi Data Lahan")]):_vm._e()],1):_vm._e()]):_vm._e()]),(
          _vm.data.main_lahan &&
          ['4', '13', '14', '19', '20'].includes(
            this.$store.state.User.role
          ) &&
          _vm.openGisEdit
        )?_c('lahan-gis-verification',{attrs:{"data":_vm.data.main_lahan,"questions":_vm.data.lahan_term_question_list.filter((x) => {
          if (_vm.verifRole == 'gis') return x.role_id == 14;
          else if (_vm.verifRole == 'um') return x.role_id == 20;
          else if (_vm.verifRole == 'fc') return x.role_id == 19;
        }),"answers":_vm.data.lahan_term_answer_list,"role":_vm.verifRole,"isCarbonProject":Array.isArray(_vm.data.lahan_project) &&
              _vm.data.lahan_project.length > 0 &&
              _vm.data.lahan_project[0].project_planting_purposes_code == 'carbon',"polygonGisArea":_vm.polygonGisArea},on:{"success":function($event){_vm.componentKey += 1;
            _vm.openGisEdit = false;
            _vm.getData();},"polygon_change":_vm.onChangePolygon,"close":function($event){_vm.openGisEdit = false;
          _vm.verifRole = null;}}}):_vm._e(),_c('div',{staticClass:"lahan-side-list"},[_c('h4',[_vm._v("Status & Log")]),(_vm.data.main_lahan)?_c('div',{staticClass:"lahan-side-item-wrapper"},[_c('div',{staticClass:"lahan-side-item"},[_c('p',{staticClass:"mb-0 label"},[_vm._v("Status")]),(_vm.getProject() === 'carbon')?_c('div',{staticClass:"d-flex flex-row value"},[_c('span',{class:{
                  'badge bg-warning':
                    _vm.data.main_lahan.approve == 0 &&
                    _vm.data.main_lahan.updated_gis.toLowerCase() == 'belum',
                  'badge bg-info':
                    _vm.data.main_lahan.approve == 0 &&
                    _vm.data.main_lahan.updated_gis.toLowerCase() == 'sudah',
                  'badge bg-primary':
                    _vm.data.main_lahan.approve == 1 &&
                    _vm.data.main_lahan.fc_complete_data != null,
                  'badge bg-success': _vm.data.main_lahan.approve == 2,
                  'badge bg-danger':
                    _vm.data.main_lahan.approve == 3 ||
                    (_vm.data.main_lahan.approve == 1 &&
                      _vm.data.main_lahan.fc_complete_data == null),
                }},[(
                    _vm.data.main_lahan.approve == 1 &&
                    _vm.data.main_lahan.fc_complete_data == null
                  )?_c('span',[_vm._v("Data Bermasalah")]):(
                    _vm.data.main_lahan.approve == 0 &&
                    _vm.data.main_lahan.updated_gis.toLowerCase() == 'belum'
                  )?_c('span',[_vm._v("Belum Diverifikasi")]):(
                    _vm.data.main_lahan.approve == 0 &&
                    _vm.data.main_lahan.updated_gis.toLowerCase() == 'sudah'
                  )?_c('span',[_vm._v("Diverifikasi GIS")]):(_vm.data.main_lahan.approve == 1)?_c('span',[_vm._v("Diverifikasi FC")]):(_vm.data.main_lahan.approve == 2)?_c('span',[_vm._v("Terverifikasi")]):(_vm.data.main_lahan.approve == 3)?_c('span',[_vm._v("Force Majeure")]):_vm._e()])]):_vm._e(),(_vm.getProject() === 'non-carbon')?_c('div',{staticClass:"d-flex flex-row value"},[_c('span',{staticClass:"badge",class:{
                  'bg-warning':
                    _vm.data.main_lahan.approve === 0 &&
                    _vm.data.main_lahan.fc_complete_data === null,
                  'bg-primary':
                    _vm.data.main_lahan.approve === 1 &&
                    _vm.data.main_lahan.fc_complete_data !== null,
                  'bg-success': _vm.data.main_lahan.approve === 2,
                }},[(
                    _vm.data.main_lahan.approve === 0 &&
                    _vm.data.main_lahan.fc_complete_data === null
                  )?_c('span',[_vm._v("Belum Diverifikasi")]):(
                    _vm.data.main_lahan.approve === 1 &&
                    _vm.data.main_lahan.fc_complete_data !== null
                  )?_c('span',[_vm._v("Data Lahan Terverifikasi")]):(_vm.data.main_lahan.approve === 2)?_c('span',[_vm._v("Terverifikasi")]):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"lahan-side-item"},[_c('p',{staticClass:"mb-0 label"},[_vm._v("Jumlah Bibit")]),_c('div',{staticClass:"d-flex flex-row value"},[(_vm.data.main_lahan.seed_is_modified == 1)?_c('span',{staticClass:"badge",class:{
                  'bg-danger': _vm.data.main_lahan.seed_verify_status == 0,
                  'bg-warning': _vm.data.main_lahan.seed_verify_status == null,
                  'bg-success': _vm.data.main_lahan.seed_verify_status == 1,
                }},[(_vm.data.main_lahan.seed_verify_status == 0)?_c('span',[_vm._v("Tidak Disetujui")]):(_vm.data.main_lahan.seed_verify_status == 1)?_c('span',[_vm._v("Disetujui")]):_c('span',[_vm._v("Menunggu Approval")])]):_c('span',[_vm._v("-")])])]),_c('div',{staticClass:"lahan-side-item"},[_c('p',{staticClass:"mb-0 label"},[_vm._v("Eligibilitas Lahan")]),_c('div',{staticClass:"d-flex flex-row value"},[_c('span',{class:{
                  'text-danger': _vm.data.main_lahan.eligible_status == 0,
                  'text-warning': _vm.data.main_lahan.eligible_status == 1,
                  'text-success': _vm.data.main_lahan.eligible_status == 2,
                }},[(_vm.data.main_lahan.eligible_status == 0)?_c('span',[_vm._v("Tidak Bisa Ikut")]):(_vm.data.main_lahan.eligible_status == 1)?_c('span',[_vm._v("Bisa Ikut Dengan Tindakan Tambahan")]):(_vm.data.main_lahan.eligible_status == 2)?_c('span',[_vm._v("Bisa Ikut")]):_c('span',[_vm._v("-")])])])]),_c('div',{staticClass:"lahan-side-item d-flex flex-col",staticStyle:{"flex-direction":"column !important"}},[_c('p',{staticClass:"mb-0 label"},[_vm._v("Log Data")]),(_vm.data.main_lahan)?_c('div',{staticClass:"d-flex flex-col log-data"},[_c('div',{staticClass:"log-data-item active"},[_c('div',{staticClass:"dot-wrapper"},[_c('div',{staticClass:"dot"})]),_c('div',{staticClass:"log-value"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatDate( _vm.data.main_lahan.created_at, "D MMMM YYYY HH:mm" )))]),_c('span',{staticClass:"label"},[_vm._v("FF Menambahkan Data Lahan")]),_c('span',{staticClass:"user"},[_vm._v("FF: "+_vm._s(_vm.data.main_lahan.field_facilitators_name))])])]),_c('div',{staticClass:"log-data-item",class:{
                  active: [0, 1].includes(_vm.data.main_lahan.fc_complete_data),
                }},[_c('div',{staticClass:"dot-wrapper"},[_c('div',{staticClass:"dot"})]),_c('div',{staticClass:"log-value"},[(_vm.data.main_lahan.fc_complete_data_at)?_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatDate( _vm.data.main_lahan.fc_complete_data_at, "D MMMM YYYY HH:mm" )))]):_vm._e(),_c('span',{staticClass:"label"},[_vm._v("FC Verifikasi Kelengkapan Data")]),(_vm.data.main_lahan.fc_complete_data_by)?_c('span',{staticClass:"user"},[_vm._v(_vm._s(_vm.data.main_lahan.fc_complete_data_by))]):_vm._e()])]),(_vm.getProject() === 'carbon')?_c('div',{staticClass:"log-data-item",class:{
                  active: _vm.data.main_lahan.gis_updated_at,
                }},[_c('div',{staticClass:"dot-wrapper"},[_c('div',{staticClass:"dot"})]),_c('div',{staticClass:"log-value"},[(_vm.data.main_lahan.gis_updated_at)?_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatDate( _vm.data.main_lahan.gis_updated_at, "D MMMM YYYY HH:mm" )))]):_vm._e(),_c('span',{staticClass:"label"},[_vm._v("GIS Verifikasi Polygon & Data Lahan")]),(_vm.data.main_lahan.gis_updated_at)?_c('span',{staticClass:"user"},[_vm._v(_vm._s(_vm.data.main_lahan.gis_officer))]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"log-data-item",class:{
                  active: _vm.data.main_lahan.approve >= 1,
                }},[_c('div',{staticClass:"dot-wrapper"},[_c('div',{staticClass:"dot"})]),_c('div',{staticClass:"log-value"},[(
                      _vm.data.main_lahan.approve >= 1 &&
                      _vm.data.main_lahan.approved_at
                    )?_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatDate( _vm.data.main_lahan.approved_at, "D MMMM YYYY HH:mm" )))]):_vm._e(),_c('span',{staticClass:"label"},[_vm._v("FC Verifikasi Data Lahan")]),(
                      _vm.data.main_lahan.approve >= 1 &&
                      _vm.data.main_lahan.approved_by
                    )?_c('span',{staticClass:"user"},[_vm._v(_vm._s(_vm.data.main_lahan.approved_by))]):_vm._e()])]),(_vm.getProject() === 'carbon')?_c('div',{staticClass:"log-data-item",class:{
                  active: _vm.data.main_lahan.approve == 2,
                }},[_c('div',{staticClass:"dot-wrapper"},[_c('div',{staticClass:"dot"})]),_c('div',{staticClass:"log-value"},[(
                      _vm.data.main_lahan.approve == 2 &&
                      _vm.data.main_lahan.update_eligible_at
                    )?_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatDate( _vm.data.main_lahan.update_eligible_at, "D MMMM YYYY HH:mm" )))]):_vm._e(),_c('span',{staticClass:"label"},[_vm._v("UM Menentukan Eligibilitas Lahan")]),(
                      _vm.data.main_lahan.approve == 2 &&
                      _vm.data.main_lahan.update_eligible_by
                    )?_c('span',{staticClass:"user"},[_vm._v(_vm._s(_vm.data.main_lahan.update_eligible_by))]):_vm._e()])]):_vm._e(),(_vm.getProject() === 'non-carbon')?_c('div',{staticClass:"log-data-item",class:{
                  active: _vm.data.main_lahan.approve == 2,
                }},[_c('div',{staticClass:"dot-wrapper"},[_c('div',{staticClass:"dot"})]),_c('div',{staticClass:"log-value"},[(
                      _vm.data.main_lahan.approve == 2 &&
                      _vm.data.main_lahan.approved_at
                    )?_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatDate( _vm.data.main_lahan.approved_at, "D MMMM YYYY HH:mm" )))]):_vm._e(),_c('span',{staticClass:"label"},[_vm._v("UM Verifikasi Data Lahan")]),(
                      _vm.data.main_lahan.approve == 2 &&
                      _vm.data.main_lahan.approved_by
                    )?_c('span',{staticClass:"user"},[_vm._v(_vm._s(_vm.data.main_lahan.approved_by))]):_vm._e()])]):_vm._e()]):_vm._e()])]):_vm._e()]),_vm._l((_vm.side),function(item,i){return (_vm.data.main_lahan)?_c('div',{key:'land-detail-row-' + i,staticClass:"lahan-side-list"},[_c('h4',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"lahan-side-item-wrapper"},_vm._l((item.items),function(v,j){return _c('div',{key:'lahan-item' + i + j,staticClass:"lahan-side-item"},[_c('p',{staticClass:"mb-0 label"},[_vm._v(_vm._s(v.label))]),_c('div',{staticClass:"value"},[(v.type === 'qrcode')?_c('p',{staticClass:"mb-0 value qrcode"},[_c('qr-code',{attrs:{"text":_vm.data.main_lahan.barcode}})],1):_vm._e(),(v.key === 'project')?_c('span',{staticClass:"mb-0 value"},_vm._l((_vm.data.lahan_project),function(project,j){return (v.key === 'project')?_c('div',{key:`project-${j}`,staticClass:"d-flex flex-col"},[_c('span',[_vm._v(_vm._s(project.projects_project_name))]),_c('div',{staticClass:"d-flex flex-row mb-2"},[_c('span',{staticClass:"badge",class:{
                        'bg-info':
                          project.project_planting_purposes_code ==
                          'carbon',
                        'bg-light':
                          project.project_planting_purposes_code !=
                          'carbon',
                      }},[_vm._v(_vm._s(project.project_planting_purposes_name))])])]):_vm._e()}),0):_vm._e(),(v.type !== 'qrcode' && v.key !== 'project')?_c('p',{staticClass:"mb-0 value"},[(v.prepend)?_c('span',[_vm._v(_vm._s(v.prepend))]):_vm._e(),_c('span',{class:{
                    [v.class || '']: true,
                  }},[_vm._v(_vm._s(_vm._f("parse")(_vm.getValue(v.key),v.transform || "no-empty")))]),(v.append)?_c('span',[_vm._v(_vm._s(v.append))]):_vm._e()]):_vm._e()])])}),0)]):_vm._e()})],2)],1)],1),_c('v-col',{attrs:{"lg":"8"}},[_c('v-card',{staticClass:"scooping-visit-detail-card mb-5",attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800"}},[_c('v-card-title',[_c('h5',{staticClass:"mb-0 pb-0"},[_vm._v("Polygon, Pohon & Lainnya")])]),(
        _vm.data.main_lahan &&
        _vm.data.main_lahan.updated_gis &&
        _vm.data.main_lahan.updated_gis.toLowerCase() == 'sudah' &&
        _vm.data.main_lahan.gis_polygon_area
      )?_c('div',{staticClass:"alert bg-info-light px-3 mx-4 py-2 d-flex flex-row br-8 mb-4"},[_c('v-icon',{staticClass:"text-info",attrs:{"large":""}},[_vm._v("mdi-information")]),_c('span',{staticClass:"text-info d-block pl-2"},[_vm._v("Luas lahan dan luas tanam dihitung berdasarkan luas polygon lahan")])],1):_vm._e(),_c('div',{staticClass:"lahan-stat-list"},[_c('div',{staticClass:"lahan-stat-item"},[_c('p',{staticClass:"mb-0 label"},[_vm._v("Tutupan Lahan")]),_c('p',{staticClass:"mb-0 value"},[(_vm.data.main_lahan)?_c('span',[_vm._v(_vm._s(_vm.data.main_lahan.tutupan_lahan)+"%")]):_vm._e()])]),_c('div',{staticClass:"lahan-stat-item"},[_c('p',{staticClass:"mb-0 label"},[_vm._v("Luas Lahan")]),(_vm.data.main_lahan)?_c('p',{staticClass:"mb-0 value"},[(
              _vm.data.main_lahan &&
              _vm.data.main_lahan.updated_gis.toLowerCase() == 'belum'
            )?_c('span',[_vm._v(_vm._s(_vm._f("parse")(_vm.data.main_lahan.land_area,"ts"))+" m²")]):(
              _vm.data.main_lahan &&
              _vm.data.main_lahan.updated_gis.toLowerCase() == 'sudah' &&
              _vm.data.main_lahan.gis_polygon_area
            )?_c('span',[_vm._v(_vm._s(_vm._f("parse")(_vm.data.main_lahan.gis_polygon_area,"ts"))+" m²")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"lahan-stat-item"},[_c('p',{staticClass:"mb-0 label"},[_vm._v("Luas Tanam")]),(_vm.data.main_lahan)?_c('p',{staticClass:"mb-0 value"},[(
              _vm.data.main_lahan &&
              _vm.data.main_lahan.updated_gis.toLowerCase() == 'belum'
            )?_c('span',[_vm._v(_vm._s(_vm._f("parse")(_vm.data.main_lahan.planting_area,"ts"))+" m²")]):(
              _vm.data.main_lahan &&
              _vm.data.main_lahan.updated_gis.toLowerCase() == 'sudah' &&
              _vm.data.main_lahan.gis_polygon_area
            )?_c('span',[_vm._v(_vm._s(_vm._f("parse")(_vm.data.main_lahan.gis_planting_area,"ts"))+" m²")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"polygon-wrapper"},[_c('div',{staticClass:"map-wrapper",staticStyle:{"height":"400px","width":"100%"}},[_c('div',{staticClass:"map-legends"},_vm._l((_vm.legends),function(item,i){return _c('div',{key:item.id,staticClass:"map-legend-item",class:{
              active: item.show,
              disabled: item.disabled,
            },on:{"click":function($event){return _vm.toggleLayer(item, i)}}},[(item.id !== 'map-coordinate')?_c('span',{staticClass:"shape",class:item.shape}):(item.id == 'map-coordinate')?_c('span',[_c('v-icon',{staticClass:"icon"},[_vm._v("mdi-map-marker")])],1):_vm._e(),_c('span',[_vm._v(_vm._s(item.label))])])}),0),(_vm.mapOpen)?_c('div',{ref:"mapContainer",staticClass:"map-container"}):_c('div',{staticClass:"map-placeholder"},[_c('v-btn',{attrs:{"variant":"success"},on:{"click":_vm.openMaps}},[_c('v-icon',[_vm._v("mdi-google-maps")]),_c('span',[_vm._v("Buka Maps")])],1)],1)]),(_vm.data.main_lahan)?_c('div',{staticClass:"lahan-photo-list d-flex flex-row"},_vm._l(([1, 2, 3, 4]),function(item,i){return (
            _vm.data.main_lahan.photo1 !== '-' ||
            _vm.data.main_lahan.photo2 !== '-' ||
            _vm.data.main_lahan.photo3 !== '-' ||
            _vm.data.main_lahan.photo4 !== '-'
          )?_c('div',{key:'lahan-photo' + i,staticClass:"lahan-photo-item",style:({
                backgroundImage:
                  'url(' +
                  _vm.$_config.baseUrlUpload +
                  '/' +
                  _vm.data.main_lahan[`photo${item}`] +
                  ')',
              }),on:{"click":function($event){return _vm.showLightbox(
              _vm.$_config.baseUrlUpload + '/' + _vm.data.main_lahan[`photo${item}`]
            )}}},[_c('h6',[_vm._v("Foto Lahan "+_vm._s(item))])]):_vm._e()}),0):_vm._e(),_c('div',{staticClass:"trees"},[_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-between"},[_c('h4',{staticClass:"mb-0 pb-0"},[_vm._v("Pohon")]),(_vm.trees.length > 1)?_c('div',{staticClass:"trees-filter"},_vm._l((_vm.trees),function(tree,i){return _c('v-btn',{key:`lahan-detail-tree-${i}`,staticClass:"mr-2",class:{
                  'font-weight-bold': tree.label == _vm.treesActive,
                },attrs:{"variant":tree.label != _vm.treesActive ? 'light' : 'success'},on:{"click":function($event){_vm.treesActive = tree.label}}},[_vm._v(_vm._s(tree.label)+" ")])}),1):_vm._e()]),(
            _vm.data.main_lahan &&
            _vm.data.main_lahan.updated_gis == 'belum' &&
            Array.isArray(_vm.data.lahan_project) &&
            _vm.data.lahan_project.length > 0 &&
            _vm.data.lahan_project[0].project_planting_purposes_code == 'carbon'
          )?_c('div',{staticClass:"d-flex flex-row align-items-center bg-warning-light px-3 py-3 br-8 mb-4 mt-3"},[_c('v-icon',{staticClass:"text-warning mr-3",attrs:{"large":""}},[_vm._v("mdi-information")]),(
              _vm.data.main_lahan.pohon_kayu == 0 &&
              _vm.data.main_lahan.pohon_mpts == 0 &&
              _vm.data.main_lahan.seed_is_modified == null
            )?_c('span',{staticClass:"text-warning"},[_vm._v("Untuk "),_c('strong',[_vm._v("Project Carbon")]),_vm._v(", data jumlah bibit akan diperbarui setelah data lahan dan polygon lahan diverifikasi oleh GIS. Update jumlah bibit dapat dilakukan melalui GEKO Mobile App")]):_vm._e(),(
              (_vm.data.main_lahan.pohon_kayu > 0 ||
                _vm.data.main_lahan.pohon_mpts > 0) &&
              _vm.data.main_lahan.seed_is_modified == null
            )?_c('span',{staticClass:"text-warning"},[_vm._v("Untuk "),_c('strong',[_vm._v("Project Carbon")]),_vm._v(", data jumlah bibit masih perkiraan. Jumlah bibit dapat diperbarui melalui GEKO Mobile App setelah data lahan dan polygon lahan diverifikasi oleh GIS.")]):_vm._e()],1):_vm._e(),(
            _vm.$_sys.isAllowed('lahan-um-verification-create') &&
            _vm.data.main_lahan &&
            _vm.data.main_lahan.seed_is_modified == 1 &&
            _vm.data.main_lahan.seed_verify_status !== 1
          )?_c('div',{staticClass:"d-flex flex-row align-items-center bg-info-light px-2 py-2 br-8 mt-3"},[_c('v-icon',{staticClass:"text-info",attrs:{"large":""}},[_vm._v("mdi-information")]),_c('span',{staticClass:"text-info pl-3"},[_vm._v("Data jumlah bibit sudah diperbarui, silahkan verifikasi jumlah bibit dan status eligibilitas lahan ")])],1):_vm._e(),_c('div',{staticClass:"tree-list"},_vm._l((Array.isArray(_vm.trees) &&
              _vm.trees.find((x) => x.label == _vm.treesActive) &&
              _vm.trees.find((x) => x.label == _vm.treesActive).data
              ? _vm.trees.find((x) => x.label == _vm.treesActive).data
              : []),function(item,i){return _c('div',{staticClass:"tree-item"},[_c('v-icon',[_vm._v("mdi-tree")]),_c('div',{staticClass:"tree-item-wrapper"},[_c('span',{staticClass:"label"},[_vm._v("Pohon "+_vm._s(item.trees_tree_name)+" ")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("parse")(item.amount,"ts")))])])],1)}),0)]),_c('div',{staticClass:"other-data mt-5"},[_c('h4',{staticClass:"mb-4 text-success"},[_vm._v("Data Lahan Lainnya")]),_c('div',{staticClass:"other-data-list"},_vm._l((_vm.mainData),function(item,i){return _c('div',{key:'key-' + i,staticClass:"other-data-item"},[_c('p',{staticClass:"mb-0 label"},[_vm._v(_vm._s(item[0]))]),(_vm.data.main_lahan)?_c('div',{staticClass:"value"},[(
                  item.length > 2 &&
                  item[2] == 'photo' &&
                  ![null, undefined, '', '-'].includes(
                    _vm.data.main_lahan[item[1]]
                  ) &&
                  _vm.data.main_lahan[item[1]] !== '-'
                )?_c('span',[_c('img',{staticClass:"main-data-img",attrs:{"src":_vm.$_config.baseUrlUpload + '/' + _vm.data.main_lahan[item[1]],"alt":""},on:{"click":function($event){return _vm.showLightbox(
                        _vm.$_config.baseUrlUpload +
                        '/' +
                        _vm.data.main_lahan[item[1]]
                      )}}})]):(
                  item.length > 2 &&
                  item[2] == 'boolean' &&
                  _vm.data.main_lahan[item[1]] !== '-' &&
                  ![null, undefined, '', '-'].includes(
                    _vm.data.main_lahan[item[1]]
                  )
                )?_c('span',[_c('span',{staticClass:"badge",class:{
                    'bg-success': _vm.data.main_lahan[item[1]] == 1,
                    'bg-danger': _vm.data.main_lahan[item[1]] == 0,
                  }},[(_vm.data.main_lahan[item[1]] == 1)?_c('v-icon',[_vm._v("mdi-circle-fill")]):(_vm.data.main_lahan[item[1]] == 0)?_c('v-icon',[_vm._v("mdi-close-circle-fill")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.data.main_lahan[item[1]] == 1 ? "Ya" : _vm.data.main_lahan[item[1]] == 0 ? "Tidak" : "-")+" ")])],1)]):(
                  ![null, undefined, '', '-'].includes(
                    _vm.data.main_lahan[item[1]]
                  )
                )?_c('span',[_c('span',[_vm._v(_vm._s(_vm.data.main_lahan[item[1]]))]),(
                    item.length > 4 &&
                    item[4] &&
                    ![null, undefined, '', '-'].includes(
                      _vm.data.main_lahan[item[1]]
                    )
                  )?_c('span',[_vm._v(_vm._s(item[4]))]):_vm._e()]):_c('span',[_vm._v("-")])]):_vm._e()])}),0)]),_c('div',{staticClass:"questions mt-5 pt-5"},[_c('h4',{staticClass:"mb-4 text-success"},[_vm._v(" Kelayakan dan Kesesuaian Lahan Project ")]),_c('table',{staticClass:"eligibility-table geko-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("No")]),_c('th',[_vm._v("Indikator")]),_c('th',[_vm._v("Status")])])]),_c('tbody',_vm._l((_vm.data.lahan_term_question_list),function(question,i){return _c('tr',{key:`question-${i}`},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(i + 1))]),_c('td',[_vm._v(_vm._s(question.question))]),_c('td',[_c('div',{staticClass:"center-v text-no-wrap"},[_c('span',{staticClass:"badge",class:{
                      'bg-danger':
                        _vm.data.lahan_term_answer_list.find(
                          (x) => x.term_id === question.id
                        ) &&
                        _vm.data.lahan_term_answer_list.find(
                          (x) => x.term_id === question.id
                        ).term_answer == 0,
                      'bg-success':
                        _vm.data.lahan_term_answer_list.find(
                          (x) => x.term_id === question.id
                        ) &&
                        _vm.data.lahan_term_answer_list.find(
                          (x) => x.term_id === question.id
                        ).term_answer == 1,
                    }},[(
                        _vm.data.lahan_term_answer_list.find(
                          (x) => x.term_id === question.id
                        ) &&
                        _vm.data.lahan_term_answer_list.find(
                          (x) => x.term_id === question.id
                        ).term_answer == 0
                      )?_c('span',{staticClass:"center-v text-no-wrap"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-close-circle-outline")]),_c('span',[_vm._v("Tidak")])],1):(
                        _vm.data.lahan_term_answer_list.find(
                          (x) => x.term_id === question.id
                        ) &&
                        _vm.data.lahan_term_answer_list.find(
                          (x) => x.term_id === question.id
                        ).term_answer == 1
                      )?_c('span',{staticClass:"center-v text-no-wrap"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-check-circle-outline")]),_c('span',[_vm._v("Ya")])],1):_c('span',[_vm._v("-")])])])])])}),0)])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }